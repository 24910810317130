import { useFetcher } from '@remix-run/react'
import { ReactNode, useEffect } from 'react'
import {
	Control,
	FieldValues,
	UseFormRegister,
	UseFormSetValue,
	useForm
} from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormContext } from '~/providers/form-provider'
import { isFetching } from '~/utils/is-fetching'
import Notification from '~/components/ui/notification'
import { messages } from '~/validation'
import clsx from 'clsx'

export type ValidatedFormProps = {
	children: (form: {
		register: UseFormRegister<FieldValues>
		getError: (name: string) => any
		control: Control<FieldValues, any>
		setValue: UseFormSetValue<FieldValues>
		resultErrorCode?: string
	}) => ReactNode | Array<ReactNode>
	formId?: string
	validator?: z.ZodType
	actionIntent?: string
	onSubmit?: (data: any) => void
	className?: string
	successTitle?: string
	successMessage?: string
	clearOnSuccess?: boolean
	fullWidth?: boolean
}

export default function ValidatedForm({
	children,
	formId,
	validator,
	actionIntent,
	onSubmit,
	className,
	successTitle,
	successMessage,
	clearOnSuccess,
	fullWidth
}: ValidatedFormProps) {
	const fetcher = useFetcher({ key: formId })
	const {
		register,
		handleSubmit,
		formState: { errors, touchedFields },
		setError,
		getFieldState,
		getValues,
		setValue,
		control,
		reset
	} = useForm({
		resolver: zodResolver(validator),
		reValidateMode: 'onSubmit',
		criteriaMode: 'all'
	})

	const resultDataAvailable =
		fetcher.state === 'idle' && !!fetcher.data && 'success' in fetcher.data
	const resultSuccess = !!(
		fetcher.data &&
		'success' in fetcher.data &&
		fetcher.data.success
	)
	const resultError = !!(
		fetcher.data &&
		'errorMessage' in fetcher.data &&
		fetcher.data.errorMessage
	)
	const resultErrorCode = resultError
		? (fetcher.data.errorMessage as string)
		: undefined

	function submit(data: any) {
		fetcher.submit(
			{ ...data, intent: actionIntent },
			{
				method: 'post'
			}
		)

		if (onSubmit) {
			onSubmit(data)
		}
	}

	function getValue(name: string) {
		return getValues(name)
	}

	function getError(name: string) {
		const error = getFieldState(name)?.error?.message
		return error ? messages[error] : undefined
	}

	useEffect(() => {
		if (resultSuccess) {
			reset()
		}
	}, [resultSuccess])

	return (
		<FormContext.Provider
			value={{
				id: formId ?? 'form',
				register,
				handleSubmit,
				errors,
				setError,
				getValue,
				getError,
				isSubmitting: isFetching(fetcher, _ => true),
				resultsAvailable: resultDataAvailable,
				resultsSuccess: resultSuccess
			}}
		>
			<div
				className={clsx(
					'flex w-full flex-col gap-4',
					!fullWidth && 'max-w-[480px]'
				)}
			>
				<fetcher.Form
					className={className ?? 'inline-flex w-full flex-col gap-8'}
					method='post'
					noValidate
					onSubmit={handleSubmit(submit)}
				>
					{children({
						register,
						getError,
						control,
						setValue,
						resultErrorCode
					})}
				</fetcher.Form>
				{resultDataAvailable &&
					(resultSuccess ? (
						<FormSubmissionSuccess
							title={successTitle ?? 'Success'}
							message={
								successMessage ??
								'Form submission was successful'
							}
						/>
					) : (
						<FormSubmissionError
							error={
								(resultErrorCode in messages
									? messages[
											resultErrorCode as keyof typeof messages
										]
									: resultErrorCode) as string
							}
						/>
					))}
			</div>
		</FormContext.Provider>
	)
}

export type FormSubmissionSuccessProps = {
	title: string
	message: string
}

export type FormSubmissionErrorProps = {
	error: string
}

export function FormSubmissionSuccess({
	title,
	message
}: FormSubmissionSuccessProps) {
	if (!title || !message) {
		return null
	}

	return (
		<div id='form-success-message'>
			<Notification
				inline
				title={title}
				message={message}
				$style={'success'}
				visible={true}
				noAnimate={false}
			/>
		</div>
	)
}

export function FormSubmissionError({ error }: FormSubmissionErrorProps) {
	return (
		<div id='form-error-message'>
			<Notification
				inline
				title={'Error'}
				message={error}
				$style={'error'}
				visible={true}
				noAnimate={false}
			/>
		</div>
	)
}
