import { createContext, useContext } from "react"
import { UseFormRegister, FieldValues, UseFormHandleSubmit, FieldErrors, UseFormSetError } from "react-hook-form"

export type FormContextType = {
    id: string
    register: UseFormRegister<FieldValues>
    handleSubmit: UseFormHandleSubmit<FieldValues, undefined>
    errors: FieldErrors<FieldValues>
    setError: UseFormSetError<FieldValues>
    getValue: (name: string) => any
    getError: (name: string) => any
    isSubmitting?: boolean
    resultsAvailable: boolean
    resultsSuccess: boolean
}

export const FormContext = createContext<FormContextType>({
    id: undefined,
    register: undefined,
    handleSubmit: undefined,
    errors: {},
    setError: () => { },
    getError: () => { },
    getValue: () => { },
    isSubmitting: false,
    resultsAvailable: false,
    resultsSuccess: false
})

export function useValidatedForm() {
    return useContext(FormContext)
}